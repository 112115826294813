<template>
  <div>
    <b-button
        @click="$router.go(-1)"
        variant="outline-warning"
        class="btn-icon my-1"
        size="sm"
    >
      <span class="">RETOUR</span>
    </b-button>
    <div class="mb-1 d-flex justify-content-end">
      <b-button @click="addFunction()" variant="primary" size="md">
        Ajouter une fonction
      </b-button>
    </div>

    <b-card-code :title="settingItem.libelle">
      <b-container fluid>
        <div :hidden="hidden">
          <b-table
            responsive="sm"
            :fields="fields"
            :items="settingItemModules"
            size="lg"
          >
            <template #cell(checkShow)="data">
              <b-form-checkbox
                v-model="data.item.show"
                inline
                :value="1"
                @change="setModule(data.item)"
              >
              </b-form-checkbox>
            </template>

            <template #cell(parameter_fonctionnalities)="data">
              <div class="d-flex flex-column">
                <div
                  v-for="fonction in data.value"
                  :key="fonction.id"
                  class=""
                  outlined
                  tile
                >
                  <b-form-checkbox
                    v-model="fonction.show"
                    inline
                    value="1"
                    unchecked-value="0"
                    @change="checkFunction(fonction)"
                    class="mb-1"
                  >
                    {{ fonction.libelle }}
                  </b-form-checkbox>
                </div>
              </div>
            </template>
          </b-table>
        </div>

        <div :hidden="hiddenEmpty" class="text-center text-secondary mt-2 mb-2">
          <empty-list></empty-list>

          <!-- <span>
					Aucune fonctionnalité disponible pour ce groupe utilisateur !
				</span> -->
        </div>
      </b-container>
    </b-card-code>

    <!-- modal-ajouter-fonction -->
    <b-modal
      v-model="addFunctionModal"
      title="Ajouter une Fonction"
      :ok-disabled="isOperating"
      @hidden="resetModalAddFonction"
      hide-footer
      centered
    >
      <b-form ref="validationAjoutFonction">
        <b-form-group>
          <validation-provider
            #default="{ errors }"
            name="Module"
            rules="required"
          >
            <label for="libelle">Modules</label>
            <!-- <v-select
              id="libelle"
              v-model="selectedModule"
              placeholder="Modules"
              :options="selectedModules"
              value="id"
              label="libelle"
              @change="checkSelectedModule()"
              @click="resetInputLibelleModalAddFonction"
              :state="errors.length > 0 ? false : null"
            /> -->
            <model-list-select
              :list="selectedModules"
              v-model="selectedModule"
              option-value="id"
              option-text="libelle"
              @input="checkSelectedModule()"
              @click="resetInputLibelleModalAddFonction"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
            <small class="text-danger">{{
              errors[0] ? "" : errorsLibelleModalAddFonction
            }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group>
          <validation-provider
            #default="{ errors }"
            name="name"
            rules="required"
          >
            <label for="name">Fonctions</label>
            <v-select
              id="name"
              v-model="selectedFonction"
              :options="selectedFonctions"
              multiple
              :state="errors.length > 0 ? false : null"
              label="name"
              placeholder=""
              @click="resetInputFonctionsModalAddFonction"
              :closeOnSelect="false"
            />
            <small class="text-danger">{{ errors[0] }}</small>
            <small class="text-danger">{{
              errors[0] ? "" : errorsFonctionsModalAddFonction
            }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group class="mb-0">
          <b-button
            variant="outline-secondary"
            type="reset"
            class="mt-2 mr-1"
            @click="addFunctionModal = false"
          >
            Annuler
          </b-button>

          <b-button
            v-if="etatButton"
            variant="primary"
            class="mt-2"
            @click="addFunctionToModule"
          >
            Enregistrer
          </b-button>

          <b-button v-else variant="primary" class="mt-2">
            <b-icon-arrow-clockwise
              animation="spin"
              font-scale="1"
            ></b-icon-arrow-clockwise>
            En cours
          </b-button>
        </b-form-group>
      </b-form>
    </b-modal>
    <b-button
        @click="$router.go(-1)"
        variant="outline-warning"
        class="btn-icon my-1"
        size="sm"
    >
      <span class="">RETOUR</span>
    </b-button>
  </div>
</template>

<script>
import { mapState } from "vuex";
import EmptyList from "@core/components/empty-list/EmptyList.vue";
import {
  BButton,
  BModal,
  BForm,
  BFormInput,
  BFormGroup,
  BBadge,
  BFormSelect,
  BFormCheckbox,
  BIconArrowClockwise,
  BRow,
  BCol,
  BContainer,
  BTable,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { ModelListSelect } from "vue-search-select";

export default {
  components: {
    BCardCode,
    BButton,
    BModal,
    BForm,
    ModelListSelect,
    EmptyList,
    BFormInput,
    BFormGroup,
    BFormSelect,
    BFormCheckbox,
    BIconArrowClockwise,
    BRow,
    BCol,
    BBadge,
    BContainer,
    vSelect,
    BTable,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      hidden: true,
      hiddenEmpty: true,
      etatButton: true,
      required,
      pageLength: 10,
      dir: false,
      nameState: null,
      libelle: null,
      settingItemModules: [],
      selected: [true],
      selectedModule: "",
      selectedFonction: [],
      selectedModules: [],
      selectedFonctions: [],
      errorsLibelleModalAddFonction: null,
      errorsFonctionsModalAddFonction: null,
      addFunctionModal: false,
      isOperating: false,
      groupesListe: [],
      settingItem: {},
      fields: [
        // A column that needs custom formatting
        { key: "libelle", label: "Module" },
        { key: "checkShow", label: "statut" },
        { key: "parameter_fonctionnalities", label: "Fonction" },
      ],
      groupItem: {},
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user.auth,
    }),
  },
  created() {
    this.getModules();
  },
  methods: {
    resetModalAddFonction() {
      this.selectedModule = "";
      this.selectedFonction = [];
      this.nameState = null;
      this.resetInputModal();
    },
    resetInputModal() {
      this.resetInputLibelleModalAddFonction();
      this.resetInputFonctionsModalAddFonction();
    },
    resetInputLibelleModalAddFonction() {
      if (this.errorsLibelleModalAddFonction) {
        this.errorsLibelleModalAddFonction = null;
      }
    },
    resetInputFonctionsModalAddFonction() {
      if (this.errorsFonctionsModalAddFonction) {
        this.errorsFonctionsModalAddFonction = null;
      }
    },
    showToast(titre, type) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: titre,
          icon: type === "success" ? "CheckSquareIcon" : "AlertCircleIcon",
          variant: type,
        },
      });
    },
    checkFormValidity() {
      const valid = this.$refs.validationAjoutGroupeUtilisateur.checkValidity();
      this.nameState = valid;
      return valid;
    },

    checkModule(mod) {
      let nbrChecked = 0;
      // // check value of module 'show
      mod.parameter_fonctionnalities.forEach((element) => {
        if (element.parameters.show) {
          nbrChecked++;
        }
      });

      if (nbrChecked) {
        mod.checkShow = true;
      } else {
        mod.checkShow = false;
      }

      return mod;
    },

    checkSelectedModule() {
      const check = this.selectedModules.find(
        ({ id }) => id === this.selectedModule
      );
      this.selectedFonctions = [];
      if (check) {
        let obj = {};
        check.fonctionnalities.forEach((element) => {
          obj = {
            id: element.id,
            name: element.libelle,
          };
          this.selectedFonctions.push(obj);
        });
      }
    },

    getModules() {
      let id = window.sessionStorage.getItem("id");
      this.$http.get(`/user-groupes/${id}`).then((result) => {
        this.settingItemModules = [];
        this.settingItem = [];
        this.selectedFonctions = [];
        this.selectedModules = [];
        this.settingItem = { ...result.data.data, ...{} };

        const resul = Object.entries(this.settingItem.modules).reverse();

        resul.forEach((element) => {
          const mod = this.checkModule(element[1]);
          this.settingItemModules.push(mod);
        });

        if (this.settingItemModules.length) {
          this.hidden = false;
          this.hiddenEmpty = true;
        } else {
          this.hidden = true;
          this.hiddenEmpty = false;
        }
      });
    },
    getGroups() {
      this.$http
        .get("user-groupes")
        .then((res) => {
          this.rows = res.data.data;
        })
        .catch((e) => {});
    },
    addFunction() {
      // IF A FUNCTION ALREADY EXISTS IN LIST, DON'T SHOW IT AGAIN
      this.selectedModule = "";
      this.selectedFonctions = [];
      this.$http
        .get(`/ajouter-fonction/${this.settingItem.id}`)
        .then((result) => {
          this.selectedModules = result.data.data;
          this.addFunctionModal = true;
          result.data.data.forEach((element) => {
            element.fonctions = [];
            if (!element.fonctionnalities) element.fonctionnalities = [];
            element.fonctionnalities.forEach((fonction) => {
              let count = 0;
              const array = [];
              const data = Object.entries(this.settingItem.modules);
              data.forEach((elements) => {
                const mod = this.checkModule(elements[1]);
                array.push(mod);
              });

              array.forEach((elements) => {
                elements.parameter_fonctionnalities.forEach((f) => {
                  if (f.libelle === fonction.libelle) {
                    count++;
                  }
                });
              });

              if (count === 0) {
                element.fonctions.push(fonction);
              }

              // if (this.checkIfFUnctionExists(fonction) == 0) {
              //   element.fonctions.push(fonction)
              // }
            });
          });
        });
    },
    checkSelectedGroup() {
      let data = this.groupesListe.find(
        ({ libelle }) => libelle == this.formItem.role
      );

      if (data) {
        this.selectedAccountType = data.libelle;

        let table = ["Chargeur", "Fournisseur", "Importateur"];

        if (table.includes(this.selectedAccountType)) {
          this.hasResources = true;
          this.getDatas(this.selectedAccountType);
        } else {
          this.hasResources = false;
        }
      }
    },

    setState(item) {
      this.isOperating = true;
      this.$http
        .put("/commuter-groupe/" + item.id, item)
        .then((result) => {
          this.isOperating = false;
          if (!result.data.success) {
            item.show = !item.show;
            this.showToast("Mise à jour non effectuée", "error");
          } else this.showToast("Mise à jour effectuée", "success");
        })
        .catch(() => {
          this.isOperating = false;
          this.showToast("Mise à jour non effectuée", "error");
        });
    },

    checkFunction(fonction) {
      let mod = this.settingItemModules.find(
        ({ id }) => id === fonction.parameters.module_id
      );

      let check = mod.parameter_fonctionnalities.find(
        ({ show }) => show == false
      );
      if (!fonction.show) {
        if (check) {
          // this.settingItemModules.find(
          //   ({ id }) => id === fonction.parameters.module_id
          // ).show = false
        }
      } else {
        this.settingItemModules.find(
          ({ id }) => id === fonction.parameters.module_id
        ).show = fonction.show;
      }

      fonction.showFunction = fonction.parameters.show;
      this.groupItem = this.getModuleToUpdate("fonction", [fonction], mod);
      this.switchFonctionnality(fonction.parameters.module_id);
    },

    getModuleToUpdate(toUpdate = null, fonctions = [], mod) {
      let obj = {
        module: {
          id: mod.id,
          value: mod.checkShow,
        },
        toUpdate: toUpdate,
        fonctions: [],
      };
      fonctions.forEach((element) => {
        obj.fonctions.push({
          id: element.id,
          value: element.showFunction ? true : false,
        });
      });
      return obj;
    },
    switchFonctionnality(module_id) {
      this.isOperating = true;
      this.$http
        .put("/commuter-fonction/" + this.settingItem.id, this.groupItem)
        .then((result) => {
          this.isOperating = false;

          this.refreshModules();

          this.showToast("Mise à jour effectuée", "success");
        })
        .catch(() => {
          this.isOperating = false;

          this.isSuccess = false;
          this.showToast("Mise à jour non effectuée", "error");
        });
    },
    refreshModules() {
      this.getModules();
    },

    setModule(mod) {
      let showModule = mod.checkShow ? true : false;
      // check value of module 'show

      mod.parameter_fonctionnalities.forEach((element) => {
        element.showFunction = showModule;
        // if (element.parameters.show != showModule) {
        //   element.show = showModule
        // }
      });
      this.groupItem = this.getModuleToUpdate(
        "module",
        mod.parameter_fonctionnalities,
        mod
      );
      this.switchFonctionnality(mod.id);
    },
    checkAddFonctionFormValidity() {
      const valid = this.$refs.validationAjoutFonction.checkValidity();
      this.nameState = valid;
      return valid;
    },

    addFunctionToModule(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();

      // Exit when the form isn't valid
      if (!this.checkAddFonctionFormValidity()) {
        return;
      }

      let form = {
        module: this.selectedModule,
        fonctions: this.selectedFonction,
      };

      this.isOperating = true;

      this.etatButton = false;

      this.$http
        .put("/ajouter-fonction/" + this.settingItem.id, form)
        .then((result) => {
          this.isOperating = false;

          if (result.data.success) {
            this.etatButton = true;
            this.addFunctionModal = false;
            //this.resetModalAddFonction()
            this.refreshModules();
            this.showToast("Mise à jour effectuée", "success");
          } else {
            this.etatButton = true;
            this.showToast(result.data.message, "danger");
          }
        })
        .catch((e) => {
          this.etatButton = true;
          this.isOperating = false;
          var err = e.response.data.message;

          if (err.module) {
            this.errorsLibelleModalAddFonction = err.module[0];
          }

          if (err.fonction) {
            this.errorsFonctionsModalAddFonction = err.fonction[0];
          }
        });

      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide("modal-prevent-closing");
      });
    },
  },
};
</script>

<style lang="scss" >
.vs__selected .vs__deselect {
  fill: seashell;
}

.v-select .vs__dropdown-menu {
  height: 100px;
}

@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/pages/ui-feather.scss";
</style>